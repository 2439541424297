import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allUserIds', 'currentEditorIds', 'isReachEditorLimitCount'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            userList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 10,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "add",
                    itemText: "加入",
                    ownClass: "search-users-add",
                    isShow: true
                },
                {
                    itemName: "id",
                    itemText: "ID",
                    ownClass: "search-users-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "姓名",
                    ownClass: "search-users-name",
                    isShow: true
                },
                {
                    itemName: "permission",
                    itemText: "權限",
                    ownClass: "search-users-permission",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        }
    },
    created: function() {
        if (this.allUserIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getUserList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getUserList() {
            return new Promise((resolve, reject) => {
                let userIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempUserId = this.allUserIds[this.loadingIndex];
                    if (!tempUserId) {
                        this.isGetAllList = true;
                        break;
                    }
                    userIds.push(tempUserId);
                    this.loadingIndex++;
                }

                if (userIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    userIds: userIds
                }

                this.userList = [];

                this.$httpRequest.post('/admin_api/user/get_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    userIds.forEach(id => {
                                        let tempUser = result[id];
                                        if (tempUser) {
                                            this.userList.push({
                                                id: tempUser.id,
                                                name: tempUser.name,
                                                permission: tempUser.permission,
                                                isSelected: this.currentEditorIds.includes(tempUser.id)
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/article/edit/_user_table_list.js: 'getUserList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/article/edit/_user_table_list.js: 'getUserList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/article/edit/_user_table_list.js: 'getUserList' get error");
                    });
            });
        },
        setNewUserToList(item) {
            $('.lms-btn.icon-circle').blur();

            if (this.isReachEditorLimitCount) {
                return;
            }

            // 新增編輯者至已選帳號中
            this.$store.commit('adminArticle/addNewEditorToList', item);
        }
    }
}
