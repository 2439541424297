import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allCourseIds'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            courseList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "status",
                    itemText: "狀態",
                    ownClass: "course-status",
                    isShow: true
                },
                {
                    itemName: "id",
                    itemText: "課程ID",
                    ownClass: "course-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "課程名稱",
                    ownClass: "course-name",
                    isShow: true
                },
                {
                    itemName: "pay-date",
                    itemText: "繳款日",
                    ownClass: "course-pay-date",
                    isShow: true
                },
                {
                    itemName: "expiry-date",
                    itemText: "到期日",
                    ownClass: "course-expiry-date",
                    isShow: true
                },
                {
                    itemName: "dropout",
                    itemText: "退課",
                    ownClass: "course-dropout",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "course-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
    },
    created: function() {
        if (this.allCourseIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getCourseList()
                    .then(() => this.busy = false)
                    .catch((error) => console.log('catched error: ' + error));
            }
        },
        getCourseList() {
            return new Promise((resolve, reject) => {
                let courseIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempCourseId = this.allCourseIds[this.loadingIndex];
                    if (!tempCourseId) {
                        this.isGetAllList = true;
                        break;
                    }
                    courseIds.push(tempCourseId);
                    this.loadingIndex++;
                }

                if (courseIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    courseIds: courseIds
                }

                this.$httpRequest.post('/api/user/get_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                result.forEach(el => {
                                    this.courseList.push({
                                        id: el.id,
                                        type: el.type,
                                        name: el.name,
                                        permission: el.permission,
                                        account: el.account
                                    });
                                });

                                resolve();
                            } else {
                                reject("admin/user/_teach_table_list.js: 'getCourseList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/user/_teach_table_list.js: 'getCourseList' get error");
                        }
                    })
                    .catch(function (error) {
                        console.log('catched error: ' + error);
                        reject("admin/user/_teach_table_list.js: 'getCourseList' get error");
                    });
            });
        },
        setStateText(state) {
            let filteredData = this.$parent.userStateList.find(el => el.stateNum == state);
            return filteredData.name;
        },
    }
}
