import { render, staticRenderFns } from "./editors.vue?vue&type=template&id=1ef3c9f7&scoped=true&"
import script from "@/assets/js/components/admin/course/edit/editors.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/admin/course/edit/editors.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/admin/course/edit/editors.scss?vue&type=style&index=0&id=1ef3c9f7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef3c9f7",
  null
  
)

export default component.exports