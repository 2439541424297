// common
const MobileUnsupported = resolve => require(["@/components/common/mobile_unsupported.vue"], resolve)
// components
const Title = resolve => require(["@/components/user/group/_title.vue"], resolve)
const GroupTableList = resolve => require(["@/components/user/group/_group_table_list.vue"], resolve)
const UserTableList = resolve => require(["@/components/user/group/_user_table_list.vue"], resolve)

export default {
    components: {
        MobileUnsupported,
        Title,
        GroupTableList,
        UserTableList,
    },
    data: function() {
        return {
            allGroupIds: [],
            allUserIds: [],
            courseName: '',
            passcode: '',
            isAdmin: false,
            isSetDataReady: {
                getInitialData: false,  // 取得群組班級資料
            },

            renderTitleCount: 0,
            renderGroupListCount: 10,
            renderCourseListCount: 100
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
    },
    watch: {
        "$route.params.groupId": {
            handler: function(newValue, oldValue) {
                if (newValue != oldValue) {
                    this.getInitialData()
                }
            }
        }
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'userGroup');
        this.getInitialData();
    },
    methods: {
        async getInitialData() {
            this.isSetDataReady.getInitialData = false;

            if (!this.$route.params.groupId) {
                await this.getGroupList();
            }
            else {
                await this.getUserList();
            }

            this.isSetDataReady.getInitialData = true;
        },
        getGroupList() {
            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/group/get_group_list_ids')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.allGroupIds = result;
                                this.renderGroupListCount++;
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getUserList() {
            return new Promise((resolve, reject) => {
                let params = {
                    groupId: this.$route.params.groupId
                }
                this.$httpRequest.post('/api/group/get_group_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.allUserIds = result.members;
                                this.courseName = result.info.name;
                                this.passcode = result.info.passcode;
                                this.isAdmin = result.info.admin == 1 ? true : false;
                                this.renderTitleCount++;
                                this.renderCourseListCount++;
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        }
    }
}
