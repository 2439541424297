import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allCourseIds'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            courseList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "type",
                    itemText: "類型",
                    ownClass: "course-type",
                    isShow: true
                },
                {
                    itemName: "id",
                    itemText: "課程ID",
                    ownClass: "course-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "課程名稱",
                    ownClass: "course-name",
                    isShow: true
                },
                {
                    itemName: "content",
                    itemText: "內容供應",
                    ownClass: "course-content",
                    isShow: true
                },
                {
                    itemName: "status",
                    itemText: "狀態",
                    ownClass: "course-status",
                    isShow: true
                },
                // {
                //     itemName: "advance",
                //     itemText: "進階",
                //     ownClass: "course-advanced",
                //     isShow: true
                // }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
    },
    created: function() {
        if (this.allCourseIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getCourseList()
                    .then(() => this.busy = false)
                    .catch((error) => console.log('catched error: ' + error));
            }
        },
        getCourseList() {
            return new Promise((resolve, reject) => {
                let courseIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempCourseId = this.allCourseIds[this.loadingIndex];
                    if (!tempCourseId) {
                        this.isGetAllList = true;
                        break;
                    }
                    courseIds.push(tempCourseId);
                    this.loadingIndex++;
                }

                if (courseIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    courseIds: courseIds
                }

                this.$httpRequest.post('/admin_api/course/get_editable_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    courseIds.forEach(id => {
                                        let tempCourse = result[id];
                                        if (tempCourse) {
                                            let type = '',
                                                state = '';

                                            switch(tempCourse.purchase_type) {
                                                case '0':
                                                    type = '購買';
                                                    break;
                                                case '1':
                                                    type = '訂閱';
                                                    break;
                                            }

                                            switch(tempCourse.state) {
                                                case '0':
                                                    state = '編輯中';
                                                    break;
                                                case '1':
                                                    state = '待審核';
                                                    break;
                                                case '2':
                                                    state = '已排程';
                                                    break;
                                                case '3':
                                                    state = '上架中';
                                                    break;
                                                case '4':
                                                    state = '已封存';
                                                    break;
                                            }

                                            this.courseList.push({
                                                id: tempCourse.id,
                                                type: type,
                                                name: tempCourse.name,
                                                provider: tempCourse.provider,
                                                state: state
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/user/_teach_table_list.js: 'getCourseList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/user/_teach_table_list.js: 'getCourseList' get error");
                        }
                    })
                    .catch(function (error) {
                        console.log('catched error: ' + error);
                        reject("admin/user/_teach_table_list.js: 'getCourseList' get error");
                    });
            });
        },
        setStateText(state) {
            let filteredData = this.$parent.userStateList.find(el => el.stateNum == state);
            return filteredData.name;
        },
    }
}
