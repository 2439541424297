import InfiniteScroll from 'vue-infinite-scroll'

export default {
    props: {
        selectedGroupId: {
            required: true,
            type: String
        }
    },
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            allGIds: [],  // g: group
            groupList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false  // 是否全部載入完畢
        }
    },
    created: function() {
        this.getGroupIds();
    },
    methods: {
        getGroupIds() {
            this.$httpRequest.get('/api/group/get_group_list_ids')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allGIds = result;

                            if (!this.allGIds.length) {
                                this.busy = false;
                                this.isGetAllList = true;
                            }

                            this.loadMore();
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            await this.getGroupList();
            this.busy = false;
        },
        getGroupList() {
            return new Promise((resolve, reject) => {
                let cIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let cId = this.allGIds[this.loadingIndex];
                    if (!cId) {
                        this.isGetAllList = true;
                        break;
                    }
                    cIds.push(cId);
                    this.loadingIndex++;
                }

                if (!cIds.length) {
                    resolve();
                    return;
                }

                let params = {
                    groupIds: cIds
                }

                this.$httpRequest.post('/api/group/get_group_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let cId of params.groupIds) {
                                    let c = result[cId];

                                    if (!c) {
                                        continue;
                                    }

                                    this.groupList.push({
                                        id: cId,
                                        name: c.name
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        }
    }
}
