import { render, staticRenderFns } from "./_course_table_list.vue?vue&type=template&id=31e0ec22&scoped=true&"
import script from "@/assets/js/components/admin/user/_course_table_list.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/admin/user/_course_table_list.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/admin/user/_course_table_list.scss?vue&type=style&index=0&id=31e0ec22&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e0ec22",
  null
  
)

export default component.exports