// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    components: {
        Dialogue
    },
    data: function() {
        return {
            isSetDataReady: {
                getWordLevels: false,  // 取得單字程度列表
            }
        }
    },
    computed: {
        wordLevels() {
            return this.$store.state.article.wordLevels;
        },
        selectedWordLevel: {
            get() {
                return this.$store.state.article.selectedWordLevel;
            },
            set(value) {
                this.$store.commit('article/setSelectedWordLevel', value);
            }
        },
        articleLevelWords: {
            get() {
                return this.$store.state.article.articleLevelWords;
            },
            set(value) {
                this.$store.commit('article/setArticleLevelWords', value);
            }
        }
    },
    mounted: function () {
        // 一開啟選擇單字程度彈窗時
        $('#selectWordLevelDialogue').on('show.bs.modal', () => {
            this.getWordLevels();
        });
    },
    methods: {
        getWordLevels() {
            this.$store.dispatch('article/getWordLevels')
                .then(() => {
                    this.isSetDataReady.getWordLevels = true;
                })
                .catch((error) => {
                    console.log('catched error: ' + error);
                });
        },
        getArticleLevelWords(level) {
            if (level.id === this.selectedWordLevel.id) {
                return;
            }

            this.selectedWordLevel = level;

            // 選擇不顯示時, 則清空選擇單字程度的單字陣列
            if (this.selectedWordLevel.id === '0') {
                this.articleLevelWords = [];
                return;
            }

            let params = {
                articleId: this.$route.params.id,
                levelTypeId: this.selectedWordLevel.id
            }

            this.$httpRequest.get('/api/article/get_words_by_level_type', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.articleLevelWords = result;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
