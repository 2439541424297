import Flickity from "flickity"
import "flickity/dist/flickity.min.css"
import "@/assets/scss/vendors/flickity.scss"

export default {
    props: ['list'],
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        }
    },
    mounted: function() {
        let adFlky = new Flickity(this.$refs.introCarousel, {
            contain: true,
            draggable: this.windowWidth < 768 ? '>1' : false,
            pauseAutoPlayOnHover: true,
            autoPlay: 5000,
            on: {
                change: () => {
                    adFlky.player.play();
                },
                pointerUp: () => {
                    adFlky.player.play();
                }
            }
        });
        $('.flickity-enabled').addClass('dot-type-2');
    }
}
