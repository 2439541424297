import { mapGetters } from 'vuex'

export default {
    props: {
        isDisableUseCoupon: {
            type: Boolean,
            default: false
        },
        originalTotalPrice: {
            required: true,
            type: Object
        },
        orderTotalPrice: {
            required: true,
            type: Object
        }
    },
    data: function() {
        return {
            isPostingApi: {
                createFreeOrder: false,  // 確認訂單(建立免費訂單)
            }
        }
    },
    computed: {
        ...mapGetters({
            currentCoursePurchaseType: 'order/currentCoursePurchaseType'
        }),
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isAndroidWebview() {
            return this.$store.state.common.isAndroidWebview;
        },
        isGiftOrder() {
            return this.$store.state.order.isGiftOrder;
        },
        isCheckToRenewOrder() {
            return this.$store.state.order.isCheckToRenewOrder;
        },
        orderItemsInfo() {
            return this.isGiftOrder ? this.$store.state.order.giftOrderItemsInfo : this.$store.state.order.orderItemsInfo;
        },
        orderItemCourseIds() {
            return this.$store.state.order.orderItemCourseIds;
        },
        confirmSelectedCoupon: {
            get() {
                return this.$store.state.order.confirmSelectedCoupon;
            },
            set(value) {
                this.$store.commit('order/setConfirmSelectedCoupon', value);
            }
        },
        currentSubTotal: {
            get() {
                return this.$store.state.order.currentSubTotal;
            },
            set(value) {
                this.$store.commit('order/setCurrentSubTotal', value);
            }
        },
        currentTotalAmount: {
            get() {
                return this.$store.state.order.currentTotalAmount;
            },
            set(value) {
                this.$store.commit('order/setCurrentTotalAmount', value);
            }
        }
    },
    methods: {
        showSelectCouponDialogue() {
            if (!this.orderItemCourseIds.length || this.isDisableUseCoupon) {
                return;
            }

            $('#selectCouponDialogue').modal('show');
        },

        confirmOrder() {
            if (!this.orderItemCourseIds.length || this.isPostingApi.createFreeOrder) {
                return;
            }

            // 若訂單總額 > 0, 則表示需付費, 導向填寫付款資訊頁面
            if (this.orderTotalPrice.price > 0) {
                this.currentSubTotal = this.originalTotalPrice.price;
                this.currentTotalAmount = this.orderTotalPrice.price;

                if (this.$route.params.orderIndex === 'cart') {
                    localStorage.removeItem('fromCourse');
                    localStorage.setItem('fromCart', '1');
                }

                this.$router.push('/checkout');
                return;
            }

            // 以下進行免費訂單建立

            let items = [];
            for (let id of this.orderItemCourseIds) {
                if (!this.orderItemsInfo[id]) {
                    continue;
                }

                let tempItem = {};

                tempItem.courseId = id;
                // 選擇訂閱方案
                if (this.orderItemsInfo[id].order.type === 'plan') {
                    tempItem.subscribePriceId = this.orderItemsInfo[id].order.id;
                }

                items.push(tempItem);
            }

            let params = {
                items: items,
                device: "web",
                purchaseType: this.currentCoursePurchaseType,
                paymentType: "credit",
                totalPrice: this.orderTotalPrice.price,
                userCouponId: this.confirmSelectedCoupon.id  // 若為免費訂單則表示一定有使用優惠券
            }

            // device
            if (this.isAndroidWebview) {
                params.device = "android";
            }

            // 贈送訂單
            if (this.isGiftOrder) {
                params.isGift = true;
                params.exchangeCount = 1;  // 兌換份數

                // 訂閱制課程是否自動續訂
                if (this.currentCoursePurchaseType === '0') {
                    params.isGiftSubscribing = this.isCheckToRenewOrder;
                }
            }

            this.isPostingApi.createFreeOrder = true;

            this.$httpRequest.post('/api/order/create_order', params)
                .then(response => {
                    this.isPostingApi.createFreeOrder = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            // 初始訂單商品相關變數
                            this.$store.commit('order/setCurrentOrderItem', {});
                            this.$store.commit('order/setCurrentGiftOrderItems', []);

                            // 由於為免費訂單, 因此當建立成功後即導向購買成功頁面
                            this.$router.push(`/checkout/${result.order_no}/success`);
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.createFreeOrder = false;
                    console.log('catched error: ' + error);
                });
        }
    }
}
