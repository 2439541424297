import { mapGetters } from 'vuex'
// components
const Sidebar = resolve => require(["@/components/article/_sidebar.vue"], resolve)

export default {
    components: {
        Sidebar
    },
    data: function() {
        return {
            isSetDataReady: {
                getArticlePracticeInfo: false,  // 取得文章練習分類資訊
            }
        }
    },
    watch: {
        userInfo(newValue) {
            this.isSetDataReady.getArticlePracticeInfo = false;
            this.getArticlePracticeInfo();

            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                this.$store.dispatch('article/updateNoteAndWords', {
                    articleId: this.$route.params.id
                });
            }
        }
    },
    computed: {
        ...mapGetters({
            isShowCourseInfoBar: 'article/isShowCourseInfoBar'
        }),
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        articlePracticeInfo() {
            return this.$store.state.article.articlePracticeInfo;
        },
        isPracticing: {
            get() {
                return this.$store.state.article.isPracticing;
            },
            set(value) {
                this.$store.commit('article/setIsPracticing', value);
            }
        },
        practicingCategory: {
            get() {
                return this.$store.state.article.practicingCategory;
            },
            set(value) {
                this.$store.commit('article/setPracticingCategory', value);
            },
        },
    },
    created: function() {
        this.getArticlePracticeInfo();
    },
    methods: {
        async getArticlePracticeInfo() {
            const payload = {
                articleId: this.$route.params.id,
            };
            await this.$store.dispatch('article/getArticlePracticeInfo', payload);
            this.isSetDataReady.getArticlePracticeInfo = true;
        },
        async getPracticeQuestionInfo(payload) {
            await this.$store.dispatch('article/getPracticeQuestionInfo', payload);
        },
        startPracticing(category) {
            if (category.isGettingQuestionInfo || !category.isEnable || category.isReachedTimes) {
                return;
            }
            // 未登入跳出註冊/登入彈窗
            if (this.$_.isEmpty(this.userInfo)) {
                $('#loginDialogue').modal('show');
                return;
            }

            // v2: 調整流程, 傳遞分類資訊到練習中頁面時再取得題目與分類資訊
            this.practicingCategory.id = category.id;
            this.practicingCategory.scoreType = category.scoreType;
            // 目前 v2 版適用類型: 朗讀、對話、翻譯、寫作
            if (['1', '3', '4', '5'].includes(category.scoreType)) {
                this.isPracticing = true;
                this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practicing/v2`);
                return;
            }

            // v1: 點擊"開始練習"時取得題目完成後再前往練習中頁面
            // 目前 v1 版適用類型: 單題、題組
            const payload = {
                articleId: this.$route.params.id,
                categoryId: category.id,
                categoryScoreType: category.scoreType,
            };

            category.isGettingQuestionInfo = true;

            this.getPracticeQuestionInfo(payload)
                .then(() => {
                    category.isGettingQuestionInfo = false;
                    this.isPracticing = true;
                    this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practicing`);
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
    }
}
